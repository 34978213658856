// Lumen 5.0.2
// Bootswatch
@font-face {
  font-family: "circe";
  src: url("../../static/font/circe/CRC55.woff") format("woff");
}
@font-face {
  font-family: "circe";
  src: url("../../static/font/circe/CRC65.woff") format("woff");
  font-weight: bold;
}

@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");

$theme: "lumen" !default;

//
// Color system
//

$white: #fff !default;
$gray-100: #f6f6f6 !default;
$gray-200: #f0f0f0 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #999 !default;
$gray-700: #555 !default;
$gray-800: #333 !default;
$gray-900: #222 !default;
$black: #000 !default;

// default
$blue: #1549ba !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ff4136 !default;
$orange: #fd7e14 !default;
$yellow: #ff851b !default;
$green: #28b62c !default;
$teal: #20c997 !default;
$cyan: #75caeb !default;

// middlebrook
$mDarkBlue: #0e3247 !default;
$mLightBlue: #5fcec8 !default;
$mRed: #ec445b !default;
$mSecondaryBlue1: #cef0ee !default;
$mSecondaryBlue2: #a3e0dd !default;

$primary: $mLightBlue !default;
$secondary: $mDarkBlue !default;
$success: $green !default;
$info: $mSecondaryBlue2 !default;
$warning: $mSecondaryBlue1 !default;
$danger: $mRed !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$min-contrast-ratio: 1.75 !default;

// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif: circe, sans-serif !default;
$font-family-title: "Abril Fatface", serif !default;

// Tables

$table-bg-scale: 0 !default;

// Buttons

$btn-font-weight: 700 !default;

// Dropdowns

$dropdown-link-color: rgba(0, 0, 0, 0.5) !default;

// Navs

$nav-tabs-border-color: $gray-200 !default;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-900 !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color !default;

// Pagination

$pagination-color: $gray-700 !default;
$pagination-bg: $gray-200 !default;
$pagination-hover-color: $pagination-color !default;
$pagination-hover-bg: $pagination-bg !default;
$pagination-active-border-color: darken($primary, 5%) !default;
$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $pagination-bg !default;

// Breadcrumbs

$breadcrumb-padding-y: 0.375rem !default;
$breadcrumb-padding-x: 0.75rem !default;
$breadcrumb-bg: $pagination-bg !default;
$breadcrumb-border-radius: 0.25rem !default;

// Modals

$modal-content-border-color: rgba($black, 0.1) !default;

// Close

$btn-close-color: $white !default;
$btn-close-opacity: 0.4 !default;
$btn-close-hover-opacity: 1 !default;
