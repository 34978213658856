@import "./variables";
@import "~bootstrap/scss/bootstrap";
// @import "./bootswatch";

$fa-font-path: "../../static/webfonts";
@import "./fontawesome/fontawesome.scss";
@import "./fontawesome/solid.scss";
@import "./fontawesome/brands.scss";

.page {
  min-height: 100svh;
  font-family: $font-family-sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-title !important;
}

.title-font {
  font-family: $font-family-title !important;
}

.full-height {
  min-height: 70vh;
}

nav {
  z-index: 10000;
}

.nav-link {
  letter-spacing: 1px;
}

#nav-phone-link {
  font-size: 1.5em;
  height: 40px;
  width: 56px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $danger;
}

.nav-phone {
  color: $white;
}

.desktop-phone-link {
  border: 1px solid $white;
  padding: 5px;
  border-radius: 5px;
}

.sticky-cta {
  top: 0;
  position: fixed !important;
  width: 100%;
  z-index: 10000000;
  display: none;
}

#sticky-cta-stars {
  width: 350px;
}

@media screen and (max-width: 768px) {
  #sticky-cta-stars {
    width: 200px;
  }
}

.jumbotron {
  min-height: 48svh;
  margin-bottom: 0px !important;
  border-radius: 0px !important;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jumbotron-image {
  height: 28svh;
}

@media screen and (max-width: 768px) {
  .jumbotron-image {
    height: 23svh;
  }
}

.tick-icon-main {
  background-color: white;
  border: 2px solid white;
  border-radius: 50%;
}

.large-text {
  font-size: 1.5em;
}

.medium-text {
  font-size: 1.25em;
}

.ctaBtn {
  font-size: 2em;
  text-transform: uppercase;
  font-weight: bolder;
}

@media screen and (max-width: 768px) {
  .ctaBtn {
    font-size: 1em;
  }
}

@media screen and (min-width: 768px) {
  #indexEligibleForm {
    transform: translateY(-17vh);
    z-index: 2;
    position: absolute;
  }
}

ul.info-section-list li {
  list-style-type: circle;
}

.type-icon {
  font-size: 4em;
}

.type-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0;
}

input[type="checkbox"] {
  -ms-transform: scale(1.3); /* IE */
  -moz-transform: scale(1.3); /* FF */
  -webkit-transform: scale(1.3); /* Safari and Chrome */
  -o-transform: scale(1.3); /* Opera */
}

#trustpilotSection {
  min-height: 515px;
}

.trustpilot-img-wrapper {
  height: 100%;
  justify-content: center;
  align-items: center;
}

.trustpilot-logo-column {
  min-height: 448px;
}

@media screen and (max-width: 768px) {
  #trustpilotSection {
    min-height: 525px;
  }

  .trustpilot-logo-column {
    min-height: 135px;
  }
}

#bottomRightImage {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.27),
      rgba(255, 255, 255, 0.27)
    ),
    url(../../static/images/computer-family.webp);
  background-position: center, center;
  background-size: cover, cover;
  background-repeat: no-repeat, no-repeat;
  margin-bottom: 0px !important;
}

@media screen and (max-width: 768px) {
  #bottomRightImage {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.27),
        rgba(255, 255, 255, 0.27)
      ),
      url(../../static/images/computer-family-small.webp);
  }
}

@media screen and (max-width: 768px) {
  .index-flow-card {
    margin: 10px;
  }

  .index-card-container {
    padding-bottom: 30px;
  }
}

.index-card-icon {
  font-size: 3em;
}

.solution-card-body {
  min-height: 505px;
}

@media screen and (max-width: 768px) {
  .solution-card-body {
    min-height: 0px;
  }
}

.card-img-top {
  height: 168px;

  background-position: center, center;
  background-size: cover, cover;
  background-repeat: no-repeat, no-repeat;
  margin-bottom: 0px !important;
}

#trust-deed-img {
  background-image: url(../../static/images/money-tree-small.webp);
}

#sequestration-img {
  background-image: url(../../static/images/cash-small.webp);
}

#map-img {
  background-image: url(../../static/images/coin-jar-small.webp);
}

#das-img {
  background-image: url(../../static/images/piggy-small.webp);
}

.wizard-icon {
  font-size: 50px;
}

.real-example-card {
  min-height: 510px;
}

.no-flex-body {
  flex: inherit !important;
}

.thumb-icon {
  font-size: 2em;
}

.situation-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.benefits-card {
  min-height: 230px;
}

.benefits-icon {
  font-size: 3em;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}
